import React from 'react'
import {Redirect} from "react-router-dom";
import Paths from "../../config/Paths";
import {clearSession} from "../../redux/actions/authentication";
import {useDispatch} from "react-redux";
import {menuSetData} from "../../redux/actions/menu";

const Logout = () => {
  const dispatch = useDispatch()

  clearSession()
  dispatch(menuSetData({
    spinner: false,
    loaded: false,
    menus: []
  }))

  return <Redirect to={Paths.LOGIN}/>
}

export default Logout
