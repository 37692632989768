const Paths = {
  LOGIN: "/login",
  LOGIN_CUSTOM: "/login-custom",
  LOGIN_CALLBACK: "/login/callback",
  RECOVER_PASSWORD: "/recover-password",
  CONFIRM_RECOVER_PASSWORD: "/confirm-recover-password/:token",
  HOME: "/",
  DASHBOARD: "/dashboard",
  LOGOUT: "/logout",
  ACCOOUNT: "/user/account",
  EXPIRE_TODAY: "/expire-today",
  EXPIRED: "/expired",
  REQUESTS: "/requests",
  CAMPAIGN: "/campaign/:id",
  CAMPAIGNS: "/campaigns",
  NEW_SECTION: "/confirm-campaigns/:id",
  CONFIRM_CAMPAIGNS: "/confirm-campaigns",
  RESERVED_CAMPAIGNS: "/reserved-campaigns",
  LOST_CAMPAIGNS: "/lost-campaigns",
  AUTHORIZATIONS: "/authorizations",
  ELEMENTS: "/elements",
  SCHEDULER: "/scheduler",
  SETTINGS: "/settings",
  NO_MATCH: "/no-match",
  NOTIFICACIONS: "/notifications",
  REPORTS: "/reports",
  FORBIDDEN: "/forbidden",
  HOLIDAYS: "/holidays",
};

export default Paths;
