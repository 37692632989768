export const Constants = {
  SEPARATOR: ".",
  SEP_DECIMAL: ",",
  USER_TYPE: {
    ADMINISTRATORS: 1,
    AUTHORIZERS: 2,
    COMMERCIAL: 3,
    BUSINESS_ASSISTANTS: 4,
    COMMERCIAL_DIRECTORS: 5,
  },
  CAMPAIGNS_STATUS: {
    CANCELLED: 1,
    CONTRACTED: 2,
    PROPOSAL: 3,
    PROPOSAL_25: 4,
    PROPOSAL_50: 5,
    PROPOSAL_75: 6,
  },
  ELEMENT_STATUS: {
    AVAILABLE: 1,
    RESERVED: 2,
    IN_CAMPAIGN: 3,
  },
  ELEMENT_CAMPAIGN: {
    AVAILABLE: "Disponible",
    RESERVED: "Reservado",
    IN_CAMPAIGN: "No disponible",
  },
  NOTIFICATION_TYPE: {
    NOTICE_OF_EXPIRATION_OF_RESERVATION_24_HOURS: 1,
    RESERVATION_EXPIRATION: 2,
    RESERVE_DROP_IN_CAMPAIGN_ELEMENT: 3,
    NEW_REQUEST_FOR_EXPIRATION_EXTENSION: 4,
    EXPIRATION_EXTENSION_REQUEST_APPROVED: 5,
    EXPIRATION_EXTENSION_REQUEST_REJECTED: 6,
  },
};
