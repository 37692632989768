import AuthenticationType from "../../types/AuthenticationType";
import ActionType from "../../types/ActionType";
import {AUTHENTICATION_SET_DATA} from "../constants/authentication";
import {getSession} from "../actions/authentication";

const initialState: AuthenticationType = {
		...getSession()
}

export default (state: AuthenticationType = initialState, action: ActionType): AuthenticationType => {
		switch (action.type) {
				case AUTHENTICATION_SET_DATA:
						return {
								...action.payload
						}
				default:
						return state
		}
}
