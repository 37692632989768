import {combineReducers} from 'redux'
import authentication from './authentication'
import dialogConfirm from './dialog-confirm'
import showDrawer from './show-drawer'
import notifications from './notifications'
import menus from './menus'

const reducers = {
  authentication,
  dialogConfirm,
  showDrawer,
  notifications,
  menus
}

export default combineReducers(reducers)
