import { registerLicense } from "@syncfusion/ej2-base";

registerLicense(
  "ORg4AjUWIQA/Gnt2VVhjQlFac1lJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RiWH9WdHZWRWFUUUw="
);

export { Config } from "./Config";
export { Constants } from "./Constants";
export { Endpoints } from "./Endpoints";
export { Events } from "./Events";
