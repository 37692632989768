import React from "react";
import UserType from "./UserType";
import IUser from "../interface/IUser";
import Worker from "./Worker";
import { Functions } from "../commons/Functions";
import { Avatar } from "antd";

export default class User {
  private _id: number;
  private _name: string;
  private _email: string;
  private _locked: boolean;
  private _expired: boolean;
  private _expiresAt: null | string;
  private _userTypeId: number;
  private _type: UserType | null;
  private _worker: Worker | null;

  constructor(user: IUser) {
    this._id = user.id;
    this._name = user.attributes.name;
    this._email = user.attributes.email;
    this._locked = user.attributes.locked;
    this._expired = user.attributes.expired;
    this._expiresAt = user.attributes.expires_at;
    this._userTypeId = user.attributes.user_type_id;
    this._type = user.relationships.type
      ? new UserType(user.relationships.type)
      : null;
    this._worker = user.relationships.worker
      ? new Worker(user.relationships.worker, user.attributes)
      : null;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get locked(): boolean {
    return this._locked;
  }

  set locked(value: boolean) {
    this._locked = value;
  }

  get expired(): boolean {
    return this._expired;
  }

  set expired(value: boolean) {
    this._expired = value;
  }

  get expiresAt(): string | null {
    return this._expiresAt;
  }

  set expiresAt(value: string | null) {
    this._expiresAt = value;
  }

  get userTypeId(): number {
    return this._userTypeId;
  }

  set userTypeId(value: number) {
    this._userTypeId = value;
  }

  get type(): UserType | null {
    return this._type;
  }

  set type(value: UserType | null) {
    this._type = value;
  }

  get worker(): Worker | null {
    return this._worker;
  }

  set worker(value: Worker | null) {
    this._worker = value;
  }

  get avatar() {
    let avatar: any;
    if (this.worker?.person?.avatar) {
      avatar = <Avatar size={40} src={this.worker?.person?.avatar.url} />;
    } else {
      avatar = (
        <Avatar size={40}>{Functions.initialLettersToUpper(this.name)}</Avatar>
      );
    }

    return avatar;
  }
}
