export const Endpoints = {
  POST_LOGIN: "login",
  GET_FORGOT_PASSWORD: "forgot-password",
  GET_FORGOT_PASSWORD_USER_INFO: "tokens/:token",
  POST_RESET_PASSWORD: "reset-password",

  GET_USERS_TYPES: "users/types",
  POST_USER: "users",
  GET_USERS: "users",
  DELETE_USER: "users/:id",
  GET_USER: "users/:id",
  PUT_USER: "users/:id",
  PUT_USER_SYNC_BOSSES: "users/:id/sync-bosses",
  PUT_USER_SYNC_ACCOUNTS: "users/:id/sync-accounts",
  PUT_USER_SYNC_SUBORDINATES: "users/:id/sync-subordinates",

  GET_NOTIFICATIONS: "notifications",
  PUT_NOTIFICATIONS_READED: "notifications/:id/readed",
  PUT_NOTIFICATIONS_READ_ALL: "notifications/read-all",

  GET_ACCOUNTS: "accounts",
  PUT_ACCOUNT: "accounts/:id",

  GET_ELEMENTS: "elements",
  GET_ELEMENTS_SCHEDULER: "elements/schedule",
  GET_ELEMENTS_MAP: "elements/map",
  GET_ELEMENTS_TYPES: "elements/types",
  GET_ELEMENT: "elements/:id",

  GET_ADJUSTMENTS: "adjustments",
  POST_ADJUSTMENTS: "adjustments",

  GET_SETTINGS: "settings",
  PUT_SETTINGS: "settings",

  GET_CAMPAIGNS: "campaigns",
  GET_CAMPAIGN_SPLIT: "campaigns/:id/split",
  POST_CAMPAIGN_SPLIT: "campaigns/:id/split",
  GET_CAMPAIGN: "campaigns/:id",
  PUT_CAMPAIGN: "campaigns/:id",
  PUT_CAMPAIGN_CONFIRM: "campaigns/:id/confirm",
  GET_CAMPAIGN_ELEMENTS: "campaigns/:id/elements",
  PUT_CAMPAIGN_OBSERVATION: "campaigns/:id/observation",
  POST_CAMPAIGN_ORDER_NOTES: "campaigns/:id/order-notes",
  PUT_CAMPAIGNS_REFRESH: "campaigns/:id/refresh",
  GET_CAMPAIGNS_EXPORT: "campaigns/:id/export",

  GET_CAMPAIGN_ELEMENT: "campaign-elements/:id",
  POST_CAMPAIGN_ELEMENT_REPLACE: "campaign-elements/:id/replace",

  GET_CITIES_ZONES: "cities/zones",

  DELETE_ORDER_NOTES_ELEMENT: "order-notes/:id/element/:element",

  GET_EXTENSION_REQUESTS: "extension-requests",
  POST_EXTENSION_REQUESTS: "extension-requests",
  PUT_EXTENSION_REQUESTS_APPROVE: "extension-requests/:id/approve",
  PUT_EXTENSION_REQUESTS_REFUSE: "extension-requests/:id/refuse",
  DELETE_EXTENSION_REQUESTS: "extension-requests/:id",

  GET_MENUS: "menus",

  GET_REPORTS_RESERVATIONS_BY_MANAGER: "reports/reserves-manager",
  GET_REPORTS_RESERVATIONS_BY_ACCOUNT: "reports/reserves-account",
  GET_REPORTS_RESERVATIONS_BY_DAY: "reports/reserves-day",
  GET_REPORTS_RESERVATIONS_BY_ITEM_TYPE: "reports/reserves-type",
  GET_REPORTS_EXPORT: "reports/export",

  GET_POSITIONS_STATS: "positions/stats",
  GET_POSITIONS_EXPIRED: "positions/expired",
  GET_POSITIONS_EXPIRE_TODAY: "positions/expire-today",

  GET_HOLIDAYS: "holidays",
  POST_HOLIDAYS: "holidays",
  PUT_HOLIDAYS: "holidays/:id",
  DELETE_HOLIDAYS: "holidays/:id",
};
