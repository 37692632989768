const Messages = {
  CAMPO_REQUERIDO: "Este campo es requerido",
  EMAIL_INVALIDO:
    "Por favor, introduce una dirección de correo electrónico válida",
  INICIAR_SESION: "Iniciar sesión",
  INICIANDO: "Iniciando...",
  CONTRASENNA: "Contraseña",
  REPETIR_CONTRASENNA: "Repetir contraseña",
  E_MAIL: "E-mail",
  RECORDAR: "Recordar",
  OLVIDE_CONTRASENNA: "Olvidé mi contraseña",
  RECUPERAR_CONTRASENNA: "Recuperar contraseña",
  CAMBIAR_CONTRASENNA: "Cambiar contraseña",
  CANCELAR: "Cancelar",
  GUARDAR: "Guardar",
  GUARDAR_CAMBIOS: "Guardar cambios",
  MIS_DATOS: "Mis datos",
  CERRAR_SESSION: "Cerrar Sesión",
  VER_TODOS: "Ver todos",
  INICIO: "Inicio",
  DASHBOARD: "Dashboard",
  REPORTE: "Reporte",
  REPORTES: "Reportes",
  EN_PRIMERA_POSICION_VENCEN_HOY: "En 1ra posición que vencen hoy",
  VER_MAS: "Ver más",
  DIGITALES: "Digitales",
  BULLETINS: "Bulletins",
  OTROS: "Otros",
  OTRO: "Otro",
  EN_LAS_ULTIMAS_48H: "En las últimas 48hs",
  PERDIDAS: "Perdidas",
  PERDIDA: "Perdida",
  CAMPANNAS: "Campañas",
  CAMPANNAS_PENDIENTES: "Campañas pendientes",
  CAMPANNA: "Campaña",
  VENCEN_HOY: "Vencen hoy",
  EN_1RA_POSICION: "%  En 1ra posición",
  EN_OTRAS_POSICIONES: "En otras  posiciones",
  VENCIMIENTO: "Vencimiento",
  RESERVAS_EN_PRIMERA_POSITCION_VENCEN_HOY:
    "Reservas en primera posición que vencen hoy",
  SOLICITAR_EXTENSION_RESERVA: "Solicitar extensión de reserva",
  CARGAR_NP: "Cargar NP",
  VER_CAMPANA: "Ver Campaña",
  TABLE: {
    filterTitle: "Menú de filtro",
    filterConfirm: "Aceptar",
    filterReset: "Restablecer",
    filterEmptyText: "Sin filtros",
    selectAll: "Seleccionar página actual",
    selectInvert: "Invertir página actual",
    selectionAll: "Seleccionar todos los datos",
    sortTitle: "Ordenar",
    expandir: "Expandir fila",
    collapse: "Colapsar fila",
    triggerDesc: "Haga clic para ordenar descendente",
    triggerAsc: "Haga clic para ordenar ascendente",
    cancelSort: "Haga clic para cancelar la ordenación",
  },
  BUSCAR_REMPLAZO: "Buscar remplazo",
  DISPOSITIVOS_PERDIDOS: "Elementos perdidos",
  RESERVAS_PERDIDAS_ULTIMAS_48: "Reservas perdidas en las últimas 48hs",
  RESERVAS_PERDIDAS: "Reservas perdidas",
  SOLICITUDES_PRIMERA_POSICION_RECIBIDAS:
    "Solicitudes de 1ra Posición recibidas",
  DISPOSITIVOS: "Elementos",
  DISPOSITIVO: "Elemento",
  SOLICITANTE: "Solicitante",
  SOLICITUDES: "Solicitudes",
  MAS_INFO: "Más info",
  APROBAR: "Aprobar",
  RECHAZAR: "Rechazar",
  VENC_HOY: "Venc. Hoy",
  RESERVAS_QUE_VENCEN_HOY: "Reservas que vencen hoy",
  BUSCAR_: "Buscar...",
  BUSCAR: "Buscar",
  FECHAS_DE_CAMPANNAS: "Fechas de campaña",
  FECHA_DE_SOLICITUD: "Fecha de solicitud",
  MONTO: "Monto",
  AUTORIZACIONES: "Autorizaciones",
  MENSAJES: "Mensajes",
  MENSAJE: "Mensaje",
  TIPO_DE_SOLICITUD: "Tipo de solicitud",
  DETALLE: "Detalle",
  FECHA_INICIO: "Fecha de inicio",
  FECHA_FIN: "Fecha de fin",
  INVERSION: "Inversión",
  DISPOSITIVOS_TOTALES: "Elementos totales",
  MAPA: "Mapa",
  LISTADO: "Listado",
  SELECCIONE: "Seleccione",
  DISPONIBLE: "Disponible",
  RESERVADO: "Reservado",
  EN_CAMPANNA: "En Campaña",
  FILTROS: "Filtros",
  UBICACION: "Ubicación",
  DEPARTAMENTO_CIUDAD: "Departamento / Ciudad",
  TIPO_DE_DISPOSITIVO: "Tipo de elemento",
  TIPOS_DE_ELEMENTOS: "Tipos de elementos",
  FECHAS_DISPONIBILIDAD: "Fecha de disponibilidad",
  PRECIO: "Precio",
  DATE_STEP_ID: "Rango de fecha",
  CON_NOTA_PEDIDO: "Con nota de pedido",
  CON_NP: "Con NP",
  CON_RESERVAS_EN_ESPERA: "Con reservas en espera",
  SOLO_ELEMENTOS_DISPONIBLES: "Solo elementos disponibles",
  CON_RESERVAS_PERDIDAS: "Con reservas perdidas",
  FILTRAR: "Filtrar",
  DATE_FORMAT: "DD/MM/YYYY",
  VER_DETALLE: "Ver detalle",
  CODIGO: "Código",
  DIRECCION: "Dirección",
  ESTADO: "Estado",
  REVISA_CORREO_POR_INSTRUCCIONES_RECUPERAR_CONTRASENNA:
    "Revisa tu correo por instrucciones para recuperar tu contraseña",
  LAS_DOS_CONTRASENNAS_NO_COINCIDEN: "Las dos contraseñas no coinciden",
  AJUSTES: "Ajustes",
  ALGORITMO_DE_POSICIONAMIENTO: "Algoritmo de posicionamiento",
  SELECCIONE_ORDEN_PESO_CADA_ITEM_CALCULO_ALGORITMO_POSICIONAMIENTO_RESERVAS:
    "Seleccione el orden (Arrastrando las filas) y el peso de cada item para el cálculo del algoritmo de posicionamiento de reservas",
  USUARIOS: "Usuarios",
  USUARIO: "Usuario",
  ASIGNAR_ROLES_CUENTAS_CADA_USUARIO: "Asigna roles y cuentas a cada usuario",
  NUEVO_USUARIO: "Nuevo usuario",
  ADMINISTRADORES: "Administradores",
  AUTORIZADORES: "Autorizadores",
  COMERCIALES: "Comerciales",
  COMERCIAL: "Comercial",
  ASISTENTES_COMERCIALES: "Asistentes comerciales",
  EDITAR: "Editar",
  ELIMINAR: "Eliminar",
  ROL: "Rol",
  CUENTAS: "Cuentas",
  ASIGNADO_A: "Asignado a",
  CUENTA: "Cuenta",
  SETEO_DE_CUENTAS: "Seteo de cuentas",
  PLAZO_VENCIMIENTO_HS_HABILES: "Plazo vencimiento (hs hábiles)",
  DIRECTOR: "Director",
  EDITAR_USUARIO: "Editar usuario",
  NOMBRE: "Nombre",
  NOMBRE_Y_APELLIDOS: "Nombre y apellidos",
  APELLIDOS: "Apellidos",
  APELLIDO: "Apellido",
  TELEFONO: "Teléfono",
  FOTO: "Foto",
  FOTO_PERFIL: "Foto de perfil",
  TIPO_DE_USUARIO: "Tipo de usuario",
  HAGA_CLIC_ARRASTRE_ARCHIVO_PARA_CARGAR:
    "Haga clic o arrastre el archivo a esta área para cargar",
  ERROR: "Error",
  NOTIFICACION: "Notificación",
  INFORMACION: "Información",
  SI: "Si",
  NO: "No",
  ESTA_SEGURO_ELIMINAR_USUARIO: "¿Estás seguro de eliminar este usuario?",
  ESTA_SEGURO_MODIFICAR_ORDER_POSICIONAMIENTO:
    "¿Estás seguro que desea modificar el orden del cálculo de posicionamiento?",
  CAMBIOS_GUARDADOS_CON_EXITO: "Cambios guardados con éxito",
  DIGITAL: "Digital",
  BULLETIN: "Bulletin",
  NP: "NP",
  DEPARTAMENTOS: "Departamentos",
  DISPOSITIVOS_CON_NP: "Elementos con NP",
  TOTAL_CAMPANNA: "Total Campaña",
  TOTAL: "Total",
  POSICION: "Posición",
  CONFIRMAR_CAMPANNA: "Confirmar campaña",
  DETALLE_DISPOSITIVO: "Detalle de elemento",
  FECHA_DE_RESERVA: "Fecha de reserva",
  VENCIMIENTO_D_RESERVA: "Vencimiento de reserva",
  VALOR_DE_CAMPANNA: "Valor en campaña",
  USUARIO_QUE_POSEE_PRIMER_POSICION: "Usuario que posee primer posición",
  SOLICITAR_1RA_POSICION: "Solicitar 1ra Posición",
  COLA_DE_ESPERA: "Cola de espera",
  PUESTO: "Puesto",
  CARGA_DE_NOTA_DE_PEDIDO: "Carga de Nota de Pedido",
  CARGAR_NOTA_DE_PEDIDO: "Cargar nota de pedido",
  EXTENSION_DE_RESERVA: "Extensión de reserva",
  SELECCIONE_NUEVA_FECHA: "Seleccione nueva fecha",
  SOLICITAR: "Solicitar",
  BUSQUEDA_DISPOSITIVO_REEMPLAZO: "Búsqueda de elemento de reemplazo",
  REMPLAZAR: "Reemplazar",
  DETALLE_DE_ELEMENTO: "Detalle de elemento",
  VENCIMIENTO_DE_RESERVA: "Vencimiento de reserva",
  DISTANCIA: "Distancia",
  LA_VISUALIZACION_DE_ELEMENTOS_NO_SE_ENCUENTRA_DISPONIBLE_PARA_ESTE_ZOOM:
    "La visualización de elementos no se encuentra disponible para este nivel de zoom",
  NO_SE_ENCONTRARON_ELEMENTOS_GEOREFERENCIADOS_PARA_LOS_FILTROS_APLICADOS:
    "No se econtraron elementos georeferenciados para los filtros aplicados",
  VENC_ARRIENDO: "Venc. Arriendo",
  EXPORTAR_EXCEL: "Exportar a Excel",
  EDITAR_OBSERVACIONES: "Editar observaciones",
  OBSERVACIONES: "Observaciones",
  POSICION_ACTUAL: "Posición actual",
  OBSERVACIONES_DE_AYUDA: "Observación de AYUDA",
  SOLICITUD_PRIMERA_POSICION: "Solicitud de primera posición",
  SOLICITAR_PRIMERA_POSICION: "Solicitar primera posición",
  PUEDE_AGREGAR_UNA_OBSERVACION_ADICIONAL:
    "Puede agregar una observación adicional",
  NP_DE_CAMPANNA: "NP de campaña",
  CARGA_DE_NOTA_DE_PEDIDO_REALIZADA: "Carga de Nota de Pedido realizada",
  ESTA_SEGURO_ELIMINAR_NP: "¿Estás seguro de eliminar esta nota de pedido?",
  VENCIMIENTO_CAMPANA: "Vencimiento campaña",
  DISPOSITIVO_SIN_GEOLOCALIZACION: "Dispositivo sin geolocalización",
  LO_SENTIMOS_LA_PAGINA_QUE_VISITASTE_NO_EXISTE:
    "Lo sentimos, la página que visitaste no existe.",
  LO_SENTIMOS_NO_TIENE_PERMISOS_PARA_VISUALIZAR_LA_PAGINA_QUE_VISITASTE:
    "Lo sentimos, no tiene permisos para visualizar la página que visistaste.",
  ACTUALIZAR: "Actualizar",
  DIRECTORES_COMERCIALES: "Directores Comerciales",
  GESTIONA_A: "Gestiona a",
  PROPUESTA_CONFIRMADA_CORRECTAMENTE:
    "Propuesta confirmada correctamente, ya puede continuar el proceso en AYUDA",
  PROPUESTA_ESTADO_INCORRECTO:
    "La propuesta se encuentra en un estado incorrecto",
  ESTA_SEGURO_CONFIRMAR_PROPUESTA: "¿Estás seguro de confirmar esta propuesta?",
  ESTA_SEGURO_QUIERE_REMPLAZAR_ELEMENTO:
    "¿Está seguro que quiere remplazar el elemento?",
  PROPUESTA_PROCESO_POSICIONAMIENTO_RESERVA_ESPERE_REFRESQUE_PAGINA:
    "Propuesta en proceso de cálculo de posicionamiento de reservas, por favor espere 1 minuto y refresque la página.",
  ULTIMA_ACTUALIZACION_DE_PROPUESTA: "Última actualización de propuesta",
  LA_PROPUESTA_SE_ENCUENTRA_PROCESO_ACTUALIZACION_ESPERE:
    "La propuesta se encuentra en proceso de actualización, por favor espere.",
  DESCRIPCION: "Descripción",
  NO_HAY_NUEVAS_NOTIFICACIONES: "No hay nuevas notificaciones",
  NOTIFICACIONES: "Notificaciones",
  NOTIFICACIONES_SIN_LEER: "Notificaciones sin leer",
  TIPO: "Tipo",
  FECHA: "Fecha",
  MARCAR_COMO_LEIDO: "Marcar como leído",
  MARCAR_TODO_COMO_LEIDO: "Marcar todas como leído",
  TODAS_LAS_NOTIFICACIONES_HAN_SIDO_MARCADAS_COMO_LEIDAS:
    "Todas las notificaciones han sido marcadas como leidas.",
  ESTA_SEGURO_QUIERE_MARCAR_COMO_LEIDAS_TODAS_LAS_NOTIFACIONES:
    "¿Está seguro que quiere marcar como leidas todas las notificaciones?",
  MARCAR_COMO_NO_LEIDO: "Marcar como no leído",
  ESTA_SEGURO_QUIERE_APROBAR_EXTENSION_RESERVA:
    "¿Está seguro que quiere aprobar la extensión de reserva?",
  ESTA_SEGURO_QUIERE_CANCELAR_EXTENSION_RESERVA:
    "¿Está seguro que quiere cancelar la extensión de reserva?",
  ESTA_SEGURO_QUIERE_RECHAZAR_EXTENSION_RESERVA:
    "¿Está seguro que quiere rechazar la extensión de reserva?",
  SOLICITUD_DE_EXTENSION_APROBADA_CORRECTAMENTE:
    "Solicitud de extensión aprobada correctamente.",
  SOLICITUD_DE_EXTENSION_RECHAZADA_CORRECTAMENTE:
    "Solicitud de extensión rechazada correctamente.",
  SOLICITUD_DE_EXTENSION_CANCELADA_CORRECTAMENTE:
    "Solicitud de extensión cancelada correctamente.",
  SOLICITUD_DE_EXTENSION_GENERADA_CORRECTAMENTE:
    "Solicitud de extensión generada correctamente.",
  SOLICITUDES_PENDIENTES: "Solicitudes pendientes",
  CIUDADES: "Ciudades",
  FECHAS: "Fechas",
  EJECUTIVO: "Ejecutivo",
  EJECUTIVOS: "Ejecutivos",
  RESERVAS_POR_EJECUTIVO: "Reservas por ejecutivo",
  RESERVAS_POR_CUENTA: "Reservas por cuenta",
  RESERVAS_POR_DIA: "Reservas por día",
  ULTIMO_ANNO: "Último año",
  RESERVAS_POR_TIPO_DE_ELEMENTO: "Reservas por tipo de elemento",
  NO_HAY_DATOS_PARA_LOS_FILTROS_SELECCIONADOS:
    "No hay datos para los filtros seleccionados",
  RANGO_DE_FECHAS: "Rango de fechas",
  INFORMADOR_DE_CAMPANNAS_CONFIRMADAS: "Informador de campañas confirmadas",
  HA_OCURRIDO_UN_ERROR_POR_FAVOR_INTENTE_MAS_TARDE:
    "Ha ocurrido un error, por favor intente más tarde.",
  FERIADOS: "Feriados",
  FERIADO: "Feriado",
  ACTIVO: "Activo",
  DESHABILITADO: "Deshabilitado",
  ESTA_SEGURO_QUIERE_ELIMINAR_ESTA_FECHA:
    "¿Está seguro que quiere eliminar esta fecha?",
  SE_HA_ELIMINADO_CORRECTAMENTE: "Se ha eliminado correctamente",
  AGREGAR_FECHA: "Agregar fecha",
  SE_HA_AGREGADO_CORRECTAMENTE: "Se ha agregado correctamente",
  SE_HA_EDITADO_CORRECTAMENTE: "Se ha actualizado correctamente",
  MES: "Mes",
  DIA: "Día",
  CONTRATADO: "Contratado",
  RESERVA_50_75: "Reserva 50% - 75%",
  INICIAR_SESION_CON_OKTA: "Iniciar sesión con okta",
  SCHEDULER: "Calendario",
  LIMPIAR_FILTROS: "Limpiar filtros",
  RANGO_DE_FECHA: "Rango de fecha",
  REQUIERE_REEMPLAZO: "Requiere reemplazo",
  CONFIRM_CAMMPAIGNS: "Campañas confirmadas",
  RESERVED_CAMMPAIGNS: "Campañas reservadas",
  LOST_CAMMPAIGNS: "Campañas perdidas",
  NOTA_DE_PEDIDO: "Nota de pedido",
  NUEVA_SECCION: "Nueva seccion",
  CAMPANNA_DE_ORIGEN: "Campaña origen",
  SELECCIONA_UNA_CAMPANNA_DE_ORIGEN: "Seleccione una campaña de origen",
  CAMPANNAS_DE_DESTINO: "Destino",
  DESTINO: "Destino",
  SELECCIONA_CAMPANNAS_DE_DESTINO: "Seleccione campañas de destino",
  CONFIRMACION: "Confirmación",
  CONFIRMA_LA_SOLICITUD: "Confirma la solicitud",
  FINALIZADO: "Finalizado",
  SE_HA_ENVIADO_LA_SOLICITUD: "Se ha enviado la solicitud",
  ATRAS: "Atrás",
  SIGUIENTE: "Siguiente",
  ENVIAR: "Enviar",
  IR_AL_DASHBOARD: "Ir al dashboard",
  HACERLO_DE_VUELTA: "Hacerlo de vuelta",
  SELECCIONADA_CAMPAIGN_COMO_CAMPANNA_DE_ORIGEN:
    "Seleccionada {{campaign}} como campaña de origen",
  SELECCIONADAS_AMOUNT_CAMPANNAS_DE_DESTINO:
    "Seleccionadas {{amount}} campañas como destino",
  SELECCIONADA_AMOUNT_CAMPANNA_DE_DESTINO:
    "Seleccionada {{amount}} campaña como destino",
  ENVIAR_SOLICITUD: "Enviar solicitud",
  MODIFICAR_CAMPANNA: "Modificar campaña",
  SIN_DATOS: "Sin datos",
  TRANSFIERE_AL_MENOS_UNA_CAMPANNA: "Transfiere al menos una campaña",
  NO_DISPONIBLE: "No disponible",
};

export default Messages;
