import HttpClient from "../../commons/HttpClient";
import {Endpoints} from "../../config";
import {Functions} from "../../commons/Functions";
import {MENU_SET_DATA, MENU_SPINNER} from "../constants/menu";
import MenusType from "../../types/MenusType";
import Menu from "../../models/Menu";
import IMenu from "../../interface/IMenu";

export function menuLoadInit() {
  return (dispatch: any) => {
    dispatch(menuSpinner(true))

    HttpClient.get(
      Endpoints.GET_MENUS,
      {},
      (response: any) => {
        let menus: Menu[] = response.data.data.map((menu: IMenu) => new Menu(menu))
        menus = menus.sort((a, b) => {
          if (a.id == b.id) {
            return 0
          }

          return a.order > b.order ? 1 : -1
        })

        dispatch(menuSetData({
          spinner: false,
          loaded: true,
          menus
        }))
      },
      (errors: any) => {
        Functions.openNotificationError(Functions.errorObject(errors))
        dispatch(menuSpinner(false))
      }
    )
  }
}

export const menuSpinner = (spinner: boolean) => {
  return {
    type: MENU_SPINNER,
    payload: {spinner},
  }
}

export const menuSetData = (data: MenusType) => {
  return {
    type: MENU_SET_DATA,
    payload: {...data},
  }
}
