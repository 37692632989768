import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import Paths from "../config/Paths";
import ErrorBoundaryRoute from "./error-boundary-route";
import Loading from "../components/loading/Loading";
import Logout from "../modules/logout/Logout";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Config } from "../config";

const Login = React.lazy(() => import("../modules/login/Login"));
const Home = React.lazy(() => import("../modules/home/Home"));
const RecoverPassword = React.lazy(
  () => import("../modules/recover-password/RecoverPassword")
);
const ExpireToday = React.lazy(
  () => import("../modules/expire-today/ExpireToday")
);
const ReservationsLost = React.lazy(
  () => import("../modules/reservations-lost/ReservationsLost")
);
const FirstPositionApplicationsReceived = React.lazy(
  () =>
    import(
      "../modules/first-position-applications-received/FirstPositionApplicationsReceived"
    )
);
const Authorizations = React.lazy(
  () => import("../modules/authorizations/Authorizations")
);
const ConfimCampaigns = React.lazy(
  () => import("../modules/confirm-campaigns/ConfirmCampaigns")
);
const ReservedCampaigns = React.lazy(
  () => import("../modules/reserved-campaigns/ReservedCampaigns")
);
const LostCampaigns = React.lazy(
  () => import("../modules/lost-campaigns/LostCampaigns")
);

const Campaigns = React.lazy(() => import("../modules/campaigns/Campaigns"));
const Scheduler = React.lazy(() => import("../modules/scheduler/Scheduler"));
const Elements = React.lazy(() => import("../modules/elements/Elements"));
const ConfirmRecoverPassword = React.lazy(
  () => import("../modules/confirm-recover-password/ConfirmRecoverPassword")
);
const Settings = React.lazy(() => import("../modules/settings/Settings"));
const Campaign = React.lazy(() => import("../modules/campaign/Campaign"));
const NoMatch = React.lazy(() => import("../modules/no-match/NoMatch"));
const Forbidden = React.lazy(() => import("../modules/forbidden/Forbidden"));
const Notifications = React.lazy(
  () => import("../modules/notifications/Notifications")
);
const Report = React.lazy(() => import("../modules/report/Report"));
const LoginCallback = React.lazy(
  () => import("../modules/login-callback/LoginCallback")
);
const LoginCustom = React.lazy(
  () => import("../modules/login-custom/LoginCustom")
);
const ModifyCampaign = React.lazy(
  () => import("../modules/new-section/ModifyCampaign")
);

const routes = [
  {
    path: Paths.LOGIN_CALLBACK,
    component: LoginCallback,
  },
  {
    path: Paths.LOGIN_CUSTOM,
    component: LoginCustom,
  },
  {
    path: Paths.LOGIN,
    component: Login,
  },
  {
    path: Paths.LOGOUT,
    component: Logout,
  },
  {
    path: Paths.RECOVER_PASSWORD,
    component: RecoverPassword,
  },
  {
    path: Paths.CONFIRM_RECOVER_PASSWORD,
    component: ConfirmRecoverPassword,
  },
  {
    path: Paths.EXPIRE_TODAY,
    component: ExpireToday,
  },
  {
    path: Paths.LOST_CAMPAIGNS,
    component: LostCampaigns,
  },
  {
    path: Paths.REQUESTS,
    component: FirstPositionApplicationsReceived,
  },
  {
    path: Paths.AUTHORIZATIONS,
    component: Authorizations,
  },
  {
    path: Paths.CAMPAIGNS,
    component: Campaigns,
  },
  {
    path: Paths.ELEMENTS,
    component: Elements,
  },
  {
    path: Paths.SCHEDULER,
    component: Scheduler,
  },
  {
    path: Paths.NEW_SECTION,
    component: ModifyCampaign,
  },
  {
    path: Paths.CONFIRM_CAMPAIGNS,
    component: ConfimCampaigns,
  },
  {
    path: Paths.RESERVED_CAMPAIGNS,
    component: ReservedCampaigns,
  },

  {
    path: Paths.SETTINGS,
    component: Settings,
  },
  {
    path: Paths.CAMPAIGN,
    component: Campaign,
  },
  {
    path: Paths.NOTIFICACIONS,
    component: Notifications,
  },
  {
    path: Paths.NO_MATCH,
    component: NoMatch,
  },
  {
    path: Paths.FORBIDDEN,
    component: Forbidden,
  },
  {
    path: Paths.REPORTS,
    component: Report,
  },
  {
    path: Paths.DASHBOARD,
    component: Home,
  },
  {
    path: Paths.HOME,
    component: Home,
  },
];

const Routes = () => {
  const componentsRoutes: any[] = [];
  const history = useHistory();

  const renderRoutes = (routes: any[], path: string) => {
    routes.forEach((route: any) => {
      if (route.routes) {
        renderRoutes(route.routes, route.path);
      }

      const routePath = (path + route.path).replace("//", "/");

      componentsRoutes.push(
        <ErrorBoundaryRoute
          key={componentsRoutes.length}
          path={routePath}
          component={route.component}
        />
      );
    });
  };

  renderRoutes(routes, "");

  const oktaAuth = new OktaAuth({
    issuer: Config.ISSUER,
    clientId: Config.CLIENT_ID,
    redirectUri: window.location.origin + Paths.LOGIN_CALLBACK,
    pkce: true,
  });

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Router>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Suspense fallback={<Loading />}>
          <Switch>{componentsRoutes}</Switch>
        </Suspense>
      </Security>
    </Router>
  );
};

export default Routes;
