import NotificationsType from "../../types/NotificationsType";
import ActionType from "../../types/ActionType";
import {NOTIFICATIONS_SET_DATA, NOTIFICATIONS_SPINNER} from "../constants/notifications";

const initialState: NotificationsType = {
  total: 0,
  notifications: [],
  spinner: false,
  loaded: false
}

export default (state: NotificationsType = initialState, action: ActionType): NotificationsType => {
  switch (action.type) {
    case NOTIFICATIONS_SPINNER:
      return {
        ...state,
        spinner: action.payload.spinner
      }
    case NOTIFICATIONS_SET_DATA:
      return {
        ...action.payload
      }
    default:
      return state
  }
}
