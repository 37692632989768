import React from "react";
import Person from "./Person";
import IWorker from "../interface/IWorker";
import Account from "./Account";
import { Avatar } from "antd";
import { Functions } from "../commons/Functions";
import User from "./User";

export default class Worker {
  private _id: number;
  private _userId: number;
  private _personId: number;
  private _positionId: null;
  private _person: Person | null;
  private _user: User | any;
  private _bosses: Worker[];
  private _subordinates: Worker[];
  private _accounts: Account[];

  constructor(worker: IWorker, attributes?: any) {
    this._id = worker.id;
    this._userId = worker.attributes.user_id;
    this._personId = worker.attributes.person_id;
    this._positionId = worker.attributes.position_id;
    this._person = worker.relationships.person
      ? new Person(worker.relationships.person)
      : null;
    this._user = worker.relationships.user
      ? new User(worker.relationships.user)
      : { email: attributes.email, name: attributes.name };
    this._bosses = worker.relationships.bosses
      ? worker.relationships.bosses.map((boss) => new Worker(boss))
      : [];
    this._subordinates = worker.relationships.subordinates
      ? worker.relationships.subordinates.map(
          (subordinate) => new Worker(subordinate)
        )
      : [];
    this._accounts = worker.relationships.accounts
      ? worker.relationships.accounts.map((account) => new Account(account))
      : [];
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get userId(): number {
    return this._userId;
  }

  set userId(value: number) {
    this._userId = value;
  }

  get personId(): number {
    return this._personId;
  }

  set personId(value: number) {
    this._personId = value;
  }

  get positionId(): null {
    return this._positionId;
  }

  set positionId(value: null) {
    this._positionId = value;
  }

  get person(): Person | null {
    return this._person;
  }

  set person(value: Person | null) {
    this._person = value;
  }

  get accounts(): Account[] {
    return this._accounts;
  }

  set accounts(value: Account[]) {
    this._accounts = value;
  }

  get user(): User | null {
    return this._user;
  }

  set user(value: User | null) {
    this._user = value;
  }

  get bosses(): Worker[] {
    return this._bosses;
  }

  set bosses(value: Worker[]) {
    this._bosses = value;
  }

  get subordinates(): Worker[] {
    return this._subordinates;
  }

  set subordinates(value: Worker[]) {
    this._subordinates = value;
  }

  get avatar() {
    let avatar: any;
    if (this.person?.avatar) {
      avatar = <Avatar size={40} src={this.person?.avatar.url} />;
    } else {
      avatar = (
        <Avatar size={40}>
          {Functions.initialLettersToUpper(
            this.user?.name ? this.user?.name : ""
          )}
        </Avatar>
      );
    }

    return avatar;
  }
}
