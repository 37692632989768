import File from "./File";
import IPerson from "../interface/IPerson";

export default class Person {
		private _id: number
		private _firstName: string
		private _secondName: null | string
		private _lastName: string
		private _secondLastName: null | string
		private _phone: null | string
		private _dni: null | string
		private _sex: null | string
		private _cityId: null | number
		private _avatar: File | null

		constructor(person: IPerson) {
				this._id = person.id
				this._firstName = person.attributes.first_name
				this._secondName = person.attributes.second_name
				this._lastName = person.attributes.last_name
				this._secondLastName = person.attributes.second_last_name
				this._phone = person.attributes.phone
				this._dni = person.attributes.dni
				this._sex = person.attributes.sex
				this._cityId = person.attributes.city_id
				this._avatar = person.relationships.avatar ? new File(person.relationships.avatar) : null
		}

		get id(): number {
				return this._id;
		}

		set id(value: number) {
				this._id = value;
		}

		get firstName(): string {
				return this._firstName;
		}

		set firstName(value: string) {
				this._firstName = value;
		}

		get secondName(): string | null {
				return this._secondName;
		}

		set secondName(value: string | null) {
				this._secondName = value;
		}

		get lastName(): string {
				return this._lastName;
		}

		set lastName(value: string) {
				this._lastName = value;
		}

		get secondLastName(): string | null {
				return this._secondLastName;
		}

		set secondLastName(value: string | null) {
				this._secondLastName = value;
		}

		get phone(): string | null {
				return this._phone;
		}

		set phone(value: string | null) {
				this._phone = value;
		}

		get dni(): string | null {
				return this._dni;
		}

		set dni(value: string | null) {
				this._dni = value;
		}

		get sex(): string | null {
				return this._sex;
		}

		set sex(value: string | null) {
				this._sex = value;
		}

		get cityId(): number | null {
				return this._cityId;
		}

		set cityId(value: number | null) {
				this._cityId = value;
		}

		get avatar(): File | null {
				return this._avatar;
		}

		set avatar(value: File | null) {
				this._avatar = value;
		}

		get fullName(): string {
				return `${this.firstName} ${this.secondName} ${this.lastName} ${this.secondLastName}`
		}
}
