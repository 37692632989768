import IAccount from "../interface/IAccount";
import Worker from "./Worker";
import {Avatar} from "antd";
import {Functions} from "../commons/Functions";
import React from "react";

export default class Account {
		private _id: number
		private _name: string
		private _externalId: string
		private _workerId: number
		private _expirationPeriod: number
		private _worker: Worker | null

		constructor(account: IAccount) {
				this._id = account.id
				this._name = account.attributes.name
				this._externalId = account.attributes.external_id
				this._workerId = account.attributes.worker_id
				this._expirationPeriod = account.attributes.expiration_period
				this._worker = account.relationships.worker ? new Worker(account.relationships.worker) : null
		}

		get id(): number {
				return this._id;
		}

		set id(value: number) {
				this._id = value;
		}

		get name(): string {
				return this._name;
		}

		set name(value: string) {
				this._name = value;
		}

		get externalId(): string {
				return this._externalId;
		}

		set externalId(value: string) {
				this._externalId = value;
		}

		get workerId(): number {
				return this._workerId;
		}

		set workerId(value: number) {
				this._workerId = value;
		}

		get expirationPeriod(): number {
				return this._expirationPeriod;
		}

		set expirationPeriod(value: number) {
				this._expirationPeriod = value;
		}

		get worker(): Worker | null {
				return this._worker;
		}

		set worker(value: Worker | null) {
				this._worker = value;
		}
}
