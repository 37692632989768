import AuthenticationType from "../../types/AuthenticationType";
import {AUTHENTICATION_SET_DATA} from "../constants/authentication";
import SessionStorage from '../../commons/SessionStorage'
import LocalStorage from '../../commons/LocalStorage'
import IUser from "../../interface/IUser";
import User from "../../models/User";

export const authenticationSetData = (data: AuthenticationType) => {
  return {
    type: AUTHENTICATION_SET_DATA,
    payload: {...data},
  }
}

type Data = {
  access_token: string
  user: IUser
}

export const setRememberMe = (rememberMe: boolean) => {
  LocalStorage.setItem('remember', rememberMe.toString())
}

export const setEmail = (email: string) => {
  LocalStorage.setItem('email', email)
}

export const setSession = (data: Data) => {
  const user = new User(data.user)

  if (LocalStorage.getItem('remember') === 'true') {
    LocalStorage.setItem('user_id', data.user.id.toString())
    LocalStorage.setItem('user_name', data.user.attributes.name)
    LocalStorage.setItem('user_role', '')
    LocalStorage.setItem('user_token', data.access_token)
    LocalStorage.setItem('user_url', user.worker?.person?.avatar?.url ? user.worker?.person?.avatar?.url : '')
  } else {
    SessionStorage.setItem('user_id', data.user.id.toString())
    SessionStorage.setItem('user_name', data.user.attributes.name)
    SessionStorage.setItem('user_role', '')
    SessionStorage.setItem('user_token', data.access_token)
    SessionStorage.setItem('user_url', user.worker?.person?.avatar?.url ? user.worker?.person?.avatar?.url : '')
  }
}

export const getSession = () => {
  let id = LocalStorage.getItem('user_id')
  let name = LocalStorage.getItem('user_name')
  let role = LocalStorage.getItem('user_role')
  let access_token = LocalStorage.getItem('user_token')
  let avatar = LocalStorage.getItem('user_url')

  if (id && name && access_token) {
    return {
      isAuthenticated: true,
      account: {
        id: parseInt(id),
        name: name,
        avatar
      },
      roles: [],
      token: access_token,

    }
  }

  id = SessionStorage.getItem('user_id')
  name = SessionStorage.getItem('user_name')
  role = SessionStorage.getItem('user_role')
  access_token = SessionStorage.getItem('user_token')
  avatar = SessionStorage.getItem('user_url')

  if (id && name && access_token) {
    return {
      isAuthenticated: true,
      account: {
        id: parseInt(id),
        name: name,
        avatar
      },
      roles: [],
      token: access_token,
    }
  }

  return {
    isAuthenticated: false,
    account: {
      id: 0,
      name: '',
      avatar: ''
    },
    roles: [],
    token: '',
  }
}

export const clearSession = () => {
  LocalStorage.clearAll()
  SessionStorage.clearAll()
}
