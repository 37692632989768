import {Constants} from '../config/Constants'
import {notification} from "antd";
import Messages from "../config/Messages";

export const Functions = {
  errorObject: (errors: any) => {
    let description = 'Ha ocurrido un error'

    if (
      errors.response &&
      errors.response.data &&
      errors.response.data.message
    ) {
      description = errors.response.data.message
    } else if (errors.response && errors.response.message) {
      description = errors.response.message
    } else if (errors.message) {
      description = errors.message
    }

    return description
  },

  initialLettersToUpper(string: string) {
    let letters = ''
    let count = 0
    if (string) {
      const words = string.split(' ')
      if (words.length > 1) {
        words.forEach((value: string) => {
          const letter = value.charAt(0)

          if (letter.length && letter !== '' && count <= 1) {
            letters += value.charAt(0)
            count++
          }
        })
      } else if (words.length == 1) {
        const word = words[0]
        letters = word.charAt(0).toUpperCase() + word.charAt(1).toUpperCase()
      }
    }

    return letters.toUpperCase()
  },

  formatNumber(num: any) {
    if (num && num > 0) {
      num += ''
      const splitStr = num.split('.')
      let splitLeft = splitStr[0]
      const splitRight =
        splitStr.length > 1 ? Constants.SEP_DECIMAL + splitStr[1] : ''
      const regx = /(\d+)(\d{3})/

      while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + Constants.SEPARATOR + '$2')
      }

      return splitLeft + splitRight
    }

    return 0
  },

  openNotificationError: (description: string, title: string = Messages.ERROR) => {
    notification.error({
      message: title,
      description: description,
      placement: 'bottomLeft',
    })
  },

  openNotificationSuccess: (description: string, title: string = Messages.NOTIFICACION) => {
    notification.success({
      message: title,
      description: description,
      placement: 'bottomLeft',
    })
  },

  openNotificationInfo: (description: string, title: string = Messages.INFORMACION) => {
    notification.info({
      message: title,
      description: description,
      placement: 'bottomLeft',

    })
  },

  handleTableChange: (pagination: any, filters: any, sorter: any, setPagination: null | ((value: any) => void), setOrder: ((value: any) => void) | null) => {
    setPagination && setPagination(pagination)

    if (sorter && setOrder) {
      switch (sorter.order) {
        case 'ascend':
          setOrder({orderBy: sorter.field, orderType: 'ASC'})
          break
        case 'descend':
          setOrder({orderBy: sorter.field, orderType: 'DESC'})
          break
        default:
          setOrder({orderBy: '', orderType: ''})
          break
      }
    }
  },

  getBase64: (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  },

  capitalizeFirstLetter: (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
