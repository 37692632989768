import IFile from "../interface/IFile";

export default class File {
		private _id: number
		private _name: string
		private _filename: string
		private _path: string
		private _url: string

		constructor(image: IFile) {
				this._id = image.id
				this._name = image.attributes.name
				this._filename = image.attributes.filename
				this._path = image.attributes.path
				this._url = image.attributes.url
		}

		get id(): number {
				return this._id;
		}

		set id(value: number) {
				this._id = value;
		}

		get name(): string {
				return this._name;
		}

		set name(value: string) {
				this._name = value;
		}

		get filename(): string {
				return this._filename;
		}

		set filename(value: string) {
				this._filename = value;
		}

		get path(): string {
				return this._path;
		}

		set path(value: string) {
				this._path = value;
		}

		get url(): string {
				return this._url;
		}

		set url(value: string) {
				this._url = value;
		}
}
