import {HIDE_DRAWER, SHOW_DRAWER} from '../constants/show-drawer'
import {ShowDrawerType} from "../../types/ShowDrawerType";

const initialState: ShowDrawerType = {
		show: false,
		placement: 'right',
		title: '',
		component: null,
		width: 300
}

export default (state = initialState, action: any) => {
		switch (action.type) {
				case SHOW_DRAWER:
						return {
								...state,
								...action.payload
						}
				case HIDE_DRAWER:
						return {
								...state,
								show: action.show,
								component: null,
								title: ''
						}
				default:
						return state
		}
}
