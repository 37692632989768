import IUserType from "../interface/IUserType";

export default class UserType {
		private _id: number
		private _name: string

		constructor(type: IUserType) {
				this._id = type.id
				this._name = type.attributes.name
		}

		get id(): number {
				return this._id;
		}

		set id(value: number) {
				this._id = value;
		}

		get name(): string {
				return this._name;
		}

		set name(value: string) {
				this._name = value;
		}
}
