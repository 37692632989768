import Paths from "./Paths";

export const Config = {
  END_POINT: "https://api.clear-channel.tolber.io/api/",
  EXTERNAL_SERVICES: "http://18.229.117.144/api/",
  BASE_URL: "https://clear-channel.tolber.io",
  PATH_DEFAULT: Paths.HOME,
  GOOGLE_MAPS_API_KEY: "AIzaSyA3yKa84EIH3GsgoP5BmD1Ww641xbMLy9Q",
  PER_PAGE: 15,
  MAP_BOX_STYLE: "mapbox://styles/mfernandezbertola/ckkytf8rb17db17lkf3ifdp17",
  MAP_BOX_KEY:
    "pk.eyJ1IjoibWZlcm5hbmRlemJlcnRvbGEiLCJhIjoiY2tvc3FncDRkMDNoNDJ2bzc1eXFlanl2YSJ9.NtC84ceFiKgkuzFosmDSIQ",
  MAP_BOX_ZOOM: 14,
  TIMEZONE: "America/Guayaquil", //'America/Montevideo' //America/Guayaquil,
  TIME_REFRESH_NOTIFICATIONS: 30 * 1000, // segundos * milisegundos,
  ISSUER: "https://clearchannel-latam.okta.com",
  CLIENT_ID: "0oachjhstYishz6iQ696",
  LOGIN_FORGOT_PASSWORD_OKTA:
    "https://dev-84712943.okta.com/api/v1/authn/recovery/password",
};
